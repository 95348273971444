export default class ServiceEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#service_uuid") ? (elem.querySelector("#service_uuid") as HTMLInputElement).value : null,
            work_package_id: elem.querySelector("#service_work_package_id") ? (elem.querySelector("#service_work_package_id option:checked") as HTMLInputElement).value : null,
            work_package_min: (elem.querySelector("#service_work_package_min") as HTMLInputElement).value,
            work_package_price: (elem.querySelector("#service_work_package_price") as HTMLInputElement).value,
            quota: (elem.querySelector("#service_quota") as HTMLInputElement).value,
        }
    }
}